/* Reusable media query values */
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1024px;

/* Styles for mobile devices */
@media (max-width: $mobile-max) {
	.icon {
		display: none;
	}
}

/* Styles for tablets */
@media (min-width: $tablet-min) and (max-width: $tablet-max) {
	.table td {
		font-size: 12px !important;
	}
	.reviewContainer {
		display: none !important;
	}
}

@media (max-width: 600px) {
	.button-no-padding {
		padding: 0 !important;
	}
}
