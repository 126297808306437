//custom classes
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.content {
	background-color: #f5f5f7;
}

.bg-grey {
	background-color: var(--grey) !important;
}

.btn {
	border: none;
}

.menu-icon {
	border: none;
}

.fw-500 {
	font-weight: 500;
}

.fw-700 {
	font-weight: 700;
}

.wid-5 {
	width: 5%;
}

.wid-10 {
	width: 10%;
}

.wid-15 {
	width: 15%;
}

.wid-20 {
	width: 20%;
}

.wid-25 {
	width: 25%;
}

.wid-40 {
	width: 40%;
}

.wid-50 {
	width: 50%;
}

.button {
	color: var(--neutral) !important;
}

.text-color-primary {
	color: var(--neutral) !important;
}
