.acdn-title,
.acdn-custom-item {
  background-color: var(--neutral-gray-100) !important;
  border: none !important;
  box-shadow: none !important;
}

.school-card {
  width: 99%;
  position: relative;
  border-radius: 20px !important;
  border: 1px solid grey !important;
  box-shadow: 0px 4px 6px 2px #21252933;
}

.icon-card {
  height: 40px !important;
  width: 40px !important;
}

.icon-container {
  width: 80px;
  height: 80px;
  padding: 2px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.acdn-card-title {
  background-color: white !important;
  border-radius: 20px !important;
  box-shadow: none !important;
}

.acdn-custom-card-item {
  background-color: white !important;
  box-shadow: 0px 4px 6px 0px #81868b33;
}

.schoolCards {
  border-radius: 10px !important;
  margin-bottom: 1rem;
  border: 1px solid rgb(235, 233, 233) !important;
}

@media (min-width: 768px) and (max-width: 991px) {
  .school-card {
    width: 90%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .school-card {
    width: 90%;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
  .school-card {
    width: 80%;
  }
}
