@import "../../scss/custom-bootstrap";
.block-title {
	color: map-get($theme-colors, primary);

	font-feature-settings: "ss01" on;

	/* Heading 1 */

	font-size: 60px;
	font-style: normal;
	font-weight: 700;
	line-height: 72px; /* 120% */
	letter-spacing: 0.45px;
}

.sub-title {
	color: map-get($theme-colors, primary);

	/* Subtitle 1 */
	font-family: "Open Sans", sans-serif;
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;
}

.sub-text {
	color: var(--neutral-900, --primary-color);
	/* Body 2/Semi Bold */
	font-family: "Open Sans", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
}
