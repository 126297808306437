.btn {
  text-align: center;
  justify-content: center;
  box-shadow: var(--box-shadow-800);
  border-radius: var(--fs-12) !important;

  &.primary {
    padding: var(--fs-6) var(--fs-12);
    background-color: var(--neutral-black);
    color: var(--neutral-100);

    &:hover {
      background-color: var(--neutral-700);
      color: var(--neutral-100);
    }
    &:disabled {
      background-color: var(--neutral-300);
      color: var(--neutral-800);
      pointer-events: none;
      border: 0;
    }
  }

  &.secondary {
    background-color: var(--white);
    border: 2px solid var(--neutral-black);
    color: var(--neutral-black);

    &:hover {
      background-color: var(--primary-100);
      color: var(--neutral-black);
      cursor: pointer;
      border: 2px solid var(--neutral-black);
    }
    &:disabled {
      background-color: var(--clr-secondary-hover);
      color: var(--clr-secondary-disabled);
      pointer-events: none;
      border: 0;
      opacity: 0.4;
    }
  }

  &.success {
    background-color: var(--primary-100);
    border: 0;
    color: var(--neutral-black);

    &:hover {
      background-color: var(--primary-100);
      color: var(--neutral-black);
      cursor: pointer;
      border: 1px solid var(--neutral-black);
    }
    &:disabled {
      background-color: var(--clr-secondary-hover);
      color: var(--clr-secondary-disabled);
      pointer-events: none;
      opacity: 0.4;
    }
  }
}
