// .input-group,
// .form-outline.searchBoxIntegrationContainer {
// 	margin-bottom: 0 !important;
// }

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px; /* Adjust this value based on your needs */
}
