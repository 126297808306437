@import "~bootstrap/scss/bootstrap";

//TODO: replace the colors with color pallete from lee

$primary-color: #141616;
$secondary-color: #befade;
$success-color: #28a745;
$warning-color: #ffc107;
$danger-color: #dc3545;
$info-color: #17a2b8;

$theme-colors: (
	primary: $primary-color,
	secondary: $secondary-color,
	success: $success-color,
	warning: $warning-color,
	danger: $danger-color,
	info: $info-color,
);

@import "~bootstrap/scss/utilities";
