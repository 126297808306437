//custom properties
:root {
  //primary
  --primary-100: #d3f9cd;
  --primary-90: #d4facde5;
  --primary-80: #d5face;
  --primary-70: #d3f8cc;
  --primary-60: #d3f9cc;
  --primary-rgba: rgba(211, 249, 204, 1);
  --clr-primary-100: var(--primary-rgba);
  --clr-primary-90: rgba(211, 249, 204, 0.9);
  --clr-primary-80: rgba(211, 249, 204, 0.8);
  --clr-primary-70: rgba(211, 249, 204, 0.7);
  --clr-primary-60: rgba(211, 249, 204, 0.6);
  --clr-primary-50: rgba(211, 249, 204, 0.5);
  --clr-primary-40: rgba(211, 249, 204, 0.4);
  --clr-primary-30: rgba(211, 249, 204, 0.3);
  --clr-primary-20: rgba(211, 249, 204, 0.2);
  --clr-primary-10: rgba(211, 249, 204, 0.1);

  //secondary
  --clr-secondary-hover: #e9fff4;

  --clr-secondary-disabled: #2f8a5f;

  --clr-secondary-100: #0c4529;
  --clr-secondary-90: rgba(12, 69, 41, 0.9);
  --clr-secondary-80: rgba(12, 69, 41, 0.8);
  --clr-secondary-70: rgba(12, 69, 41, 0.7);
  --clr-secondary-60: rgba(12, 69, 41, 0.6);
  --clr-secondary-50: rgba(12, 69, 41, 0.5);
  --clr-secondary-40: rgba(12, 69, 41, 0.4);
  --clr-secondary-30: rgba(12, 69, 41, 0.3);
  --clr-secondary-20: rgba(12, 69, 41, 0.2);
  --clr-secondary-10: rgba(12, 69, 41, 0.1);

  //semantic yellow

  --semantic-yellow: #ffc340;
  --semantic-yellow-100: var(--semantic-yellow);
  --semantic-yellow-80: rgba(255, 195, 64, 0.8);
  --semantic-yellow-50: rgba(255, 195, 64, 0.5);
  --semantic-yellow-25: rgba(255, 195, 64, 0.25);

  //semantic red

  --semantic-red: #ff7070;
  --semantic-red-100: var(--semantic-red);
  --semantic-red-80: rgba(255, 112, 112, 0.8);
  --semantic-red-50: rgba(255, 112, 112, 0.5);
  --semantic-red-25: rgba(255, 112, 112, 0.25);

  //neutral

  --neutral: #141616;
  --neutral-black: var(--neutral, --neutral);

  --neutral-white: (#ced4da, #fff);
  --neutral-grey: (#f5f5f7, #f5f5f7);
  --neutral-gray-100: #f1f1f1;
  --neutral-gray-300: #cbd5e1;
  --neutral-gray-900: #0f172a;
  --neutral-900: var(--neutral, --neutral);
  --neutral-800: rgba(20, 22, 22, 0.8);
  --neutral-700: rgba(20, 22, 22, 0.7);
  --neutral-600: rgba(20, 22, 22, 0.6);
  --neutral-500: rgba(20, 22, 22, 0.5);
  --neutral-400: rgba(20, 22, 22, 0.4);
  --neutral-300: rgba(20, 22, 22, 0.3);
  --neutral-200: rgba(20, 22, 22, 0.2);
  --neutral-100: #f8f9fa;

  --white: white;
  --black: black;
  --grey: "#f5f5f7";
  --grey80: "#ccc";

  --logoGradientDark: #222f35;
  --logoGradientLight: #0f7f5d;

  // font size (1rem = 16px)

  --fs-60: 3.75rem;

  --fs-48: 3rem;
  --fs-40: 2.5rem;
  --fs-28: 1.75rem;
  --fs-24: 1.5rem;
  --fs-20: 1.25rem;
  --fs-18: 1.125rem;
  --fs-16: 1rem;
  --fs-12: 0.75rem;
  --fs-10: 0.625rem;
  --fs-8: 0.5rem;
  --fs-6: 0.3rem;

  // font-families
  --ff-open-sans: "Open Sans", sans-serif;

  //shadows
  --box-shadow-800: 0px 0px 1px 0px rgba(33, 37, 41, 0.08),
    0px 2px 2px 0px rgba(33, 37, 41, 0.06);

  --box-shadow-900: 0px 0px 1px 0px rgba(33, 37, 41, 0.32),
    0px 4px 6px 0px rgba(33, 37, 41, 0.2);

  //radius
  --radius-20: var(--fs-20);
  --radius-8: var(var(--fs-8));

  //padding
  --pd-l: 12px 24px;
  --pd-m: 12px 20px;
}
