@import "../src/scss/theme.scss";
@import "../src/scss/media-query.scss";
@import "../src/scss/custom-classes.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
p,
ul,
li,
figure,
picture {
  margin: 0;
  text-decoration: none;
  list-style-type: none;
}

body {
  font-family: var(--ff-open-sans) !important;
  font-size: var(--fs-16);
  color: var(--neutral);

  line-height: 1.5;
  height: 100vh;
  overflow-y: hidden;
}

html {
  font-family: sans-serif;
  font-size: 14px;
}

table {
  border: 1px solid lightgray;
}

tbody {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

img,
picture {
  max-width: 100%;
  display: block;
}

.app {
  overflow-x: hidden;
}

.g_id_signin {
  display: block;
  transform: scale(1.2);
}

.separator {
  border-bottom: 1px solid var(--clr-primary-100);
}

.btn-add {
  padding: 5px 10px 5px 10px;
  border-radius: 2px;
  border: none;
  background-color: var(--clr-primary-100);
  color: #343a40;
}

.btn-remove {
  padding: 0px 4px 1px 4px;
  border-radius: 25px;
  border: none;
  font-size: 8px;
  background-color: #ff7070;
  color: #343a40;
}

.btn-customPrimary {
  width: 480px;
  height: 48px;
  padding: 12px 24px 12px 24px;
  border-radius: 12px;
  border: none;
  background-color: #63f0ad;
  color: #343a40;
}

.btn-customSecondary {
  width: 480px;
  height: 48px;
  padding: 12px 24px 12px 24px;
  border-radius: 12px;
  border: 2px solid #63f0ad;
  background-color: #fff;
}

.btn-customContentPrimary {
  min-height: 40px;
  padding: 8px 20px 8px 20px;
  border-radius: 12px;
  border: none;
  background-color: #63f0ad;
  color: #343a40;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
}

.btn-customContentPrimaryModal {
  min-height: 36px;
  min-width: 60px;
  padding: 6px 12px 6px 12px;
  border-radius: 12px;
  border: none;
  background-color: #63f0ad;
  color: #343a40;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
}

.btn-customContentPlainModal {
  min-height: 36px;
  min-width: 60px;
  padding: 6px 12px 6px 12px;
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  color: #343a40;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
}

.btn-customContentWarningModal {
  min-height: 36px;
  min-width: 60px;
  padding: 6px 12px 6px 12px;
  border-radius: 12px;
  border: none;
  background-color: #ff7070;
  color: #343a40;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
}

.btn-customContentSecondaryOutline {
  min-height: 40px;
  padding: 8px 20px 8px 20px;
  border-radius: 12px;
  color: #343a40;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
  border: 2px solid #63f0ad;
  background-color: #fff;
}

.btn-customContentSecondary {
  min-height: 40px;
  padding: 8px 20px 8px 20px;
  border-radius: 12px;
  border: none;
  background-color: #63f0ad;
  color: #343a40;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
}

.btn-customContentPlain {
  min-height: 40px;
  padding: 8px 20px 8px 20px;
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  color: #343a40;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
}

.btn-customContentWarning {
  min-height: 40px;
  padding: 8px 20px 8px 20px;
  border-radius: 12px;
  border: none;
  background-color: #ff7070;
  color: #343a40;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
}

.searchBoxIntegrationContainer {
  width: inherit;
}

.searchBoxIntegration {
  width: inherit;
  height: 44px;
  padding: 10px 16px 10px 12px;
  border-radius: 100px !important;
  border: 1px solid #ced4da;
}

.searchBoxIntegration:focus-visible {
  border-color: #befade !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(190, 250, 222, 0.5) !important;
}

.tableHeading {
  background-color: var(--neutral-black) !important;
  color: var(--white) !important;
  cursor: pointer;
}

.table-striped>tbody>tr:nth-child(2n + 1)>td,
.table-striped>tbody>tr:nth-child(2n + 1)>th {
  background-color: #f8f9fa;
}

.rounded-table thead tr th:first-child {
  border-top-left-radius: 4px;
}

.rounded-table thead tr th:last-child {
  border-top-right-radius: 4px;
}

.form-control:focus {
  border-color: #befade !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(190, 250, 222, 0.5) !important;
}

/*Login Start*/
.loginContainer {
  overflow: hidden;
}

.signInContainer {
  height: 100vh;
  background: var(--grey);
}

.signInChildContainer {
  width: 480px;
  height: 460px;
  display: table;
  margin: auto;
  top: 25%;
  position: relative;
}

.signInLabel {
  border-bottom: 32px;
}

.signInPadding {
  padding-top: 44px;
}

.accountPadding {
  padding-top: 20px;
}

.loginLink {
  color: #21292e;
  font-weight: 600;
}

.google-icon {
  width: 20px;
  height: auto;
  margin-right: 8px;
}

.reviewContainer {
  background-color: #041f1e;
  height: 100vh;
}

.reviews {
  display: table;
  margin: auto;
  width: 592px;
  position: relative;
  top: 40%;
}

.reviewStars {
  justify-content: center;
}

.removePadding {
  flex: 0 0 !important;
}

.quoteText {
  color: #f8f9fa;
  font-weight: bold;
}

.reviewName {
  font-weight: 600;
}

/*Login End*/

/*API Integrations Start*/

.apiContentContainer {
  height: 688px;
  border-radius: 12px;
  padding: 32px 16px 32px 16px;
  margin-bottom: 24px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px 0px #21252933;
}

.apiCloud {
  width: 197px;
  height: 131px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 614px !important;
  display: table;
  margin: auto;
  top: 20%;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.close-icon {
  cursor: pointer;
  margin-top: -5px;
  margin-left: 10px;
}

.form-check-input:focus {
  border-color: #befade !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(190, 250, 222, 0.5) !important;
}

/*API Integrations End*/

/*Weblog Stream Start*/

.webLogStreamContentContainer {
  min-height: 688px;
  border-radius: 1px;
  padding: 32px 16px 32px 16px;
  margin-bottom: 24px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px 0px #21252933;
  height: 90vh;
}

.label {
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  font-weight: 600;

  @media screen and (max-width: 1366px) {
    font-size: 12px !important;
  }
}

.form-select {
  width: 150px;
}

.connectionButtonsContainer {
  margin-top: 30px;
}

.custom-switch {
  transform: scale(1.5);
}

.form-check-input:checked {
  background-color: #63f0ad !important;
  border-color: #befade !important;
  box-shadow: 0 0 0 0.25rem rgba(190, 250, 222, 0.5) !important;
}

.custom-switch:checked,
.custom-switch:active,
.custom-switch:focus {
  background-color: var(--neutral-black) !important;
  // color: #befade !important;
  // border-color: #befade !important;
  // box-shadow: 0 0 0 0.25rem rgba(190, 250, 222, 0.5) !important;
}

/*Weblog Stream End*/

/*Authentication Methods Start*/

.authMethodContentContainer {
  height: 688px;
  border-radius: 12px;
  padding: 32px 16px 32px 16px;
  margin-bottom: 24px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px 0px #21252933;
}

/*Authentication Methods End*/

/* Styles for tablet devices */
@media (min-width: 768px) and (max-width: 1253px) {
  .headerContainer {
    height: 50px;
    display: inline !important;
    background-color: #ffffff;
  }

  .headerLogo {
    background-color: #21292e;
    height: 50px;
    padding: 10px;
    min-width: 250px !important;
  }

  .headerItems {
    padding-right: 0px !important;
  }

  .logo-img {
    width: 150px !important;
    height: 30px;
    margin-left: 10px;
  }

  .navItem {
    display: none;
  }

  .navItemIcon {
    display: none;
  }

  .navItemList {
    display: inline !important;
  }

  .infoContainer {
    position: absolute;
    bottom: 0;
    width: 50px;
    box-shadow: none;
  }

  .logo-sideBar {
    display: none !important;
  }

  .nav-icon-img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .navLabelContainer {
    height: 56px;
    padding: 16px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .navLabelChildContainer {
    height: 58px;
    padding-left: 32px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .navSelectionContainer {
    width: 186px;
    height: 116px;
  }

  .navNotificationLabel {
    color: #343a40;
    text-decoration: none;
    font-weight: 700;
    padding: 16px;
    font-size: 12px;
    cursor: pointer;
  }

  .navLabel {
    color: #343a40;
    text-decoration: none;
    font-weight: 700;
    padding: 16px;
    font-size: 16px;
    width: 146px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .navLabelChild {
    color: #343a40;
    text-decoration: none;
    padding-left: 32px;
    margin-bottom: 10px;
    font-size: 16px;
    width: 146px;
    cursor: pointer;
  }

  .navSelection {
    width: 166px;
    height: 35px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    margin-top: 5px;
  }

  .navLabelSelection {
    color: #343a40;
    text-decoration: none;
    font-weight: 700;
    padding-bottom: 10px;
    margin-left: 32px;
  }

  .navSelectionOption {
    width: 186px;
    height: 50px;
    border-radius: 10px;
    border: 1px;
    padding: 12px 20px 12px 20px;
    margin-bottom: 6px;
  }

  .userText {
    font-weight: 400;
    cursor: pointer;
  }

  .dashboardContainer {
    height: 150px !important;
    display: flex;
    flex-direction: column;
  }
}

.objectMainContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.objectHeaderContainer {
  margin-bottom: 10px;
}

.objectContentContainer {
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 32px;
  border-radius: 16px;
  padding: 32px 16px 8px 16px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px 2px #21252933;
  display: flex;
  flex-direction: column;
}

.schoolContentContainer {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 32px;
  padding: 8px 4px 8px 4px;
  display: flex;
  flex-direction: column;
}

.scrollableContainer {
  padding-top: 18px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.objectTableContainer {
  flex: 1;
  margin-bottom: 2px;
  overflow: auto;
}

.paginationContainer {
  align-self: flex-end;
}

.scrollbar-container {
  max-height: 70%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-container::-webkit-scrollbar {
  display: none;
}

.table-striped tbody tr.selectedRow td {
  background-color: var(--primary-100);
}

.table-striped tbody tr.dataRows:hover td {
  cursor: pointer;
  background-color: var(--primary-100);
}

.logo-adminSideBar {
  height: 80px;
  background-color: #21292e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-as-anchor {
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
}

.btn-as-anchor:hover {
  background-color: var(--primary-100);
}

.titleBar {
  height: 80px;
  background-color: #21292e;
  color: white;
  margin-top: 0px !important;
}

.urlLink {
  text-decoration: underline !important;
  color: var(--clr-secondary-100) !important;
}

.urlLink:hover {
  background-color: transparent !important;
}

.video-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}