$sidebar-width: 238px;
$sidebar-width-large: 180px;
$sidebar-width-small: 60px;

@mixin sidebar-width($width) {
  min-width: $width;
  max-width: $width;
}

.mainContainer {
  height: 100vh;
}

.sidebarContainer {
  float: left;
  width: fit-content;
  height: 100vh;
}

.contentContainer {
  width: calc(100% - $sidebar-width);
  height: 100vh;
  overflow: hidden;
  background-color: #f5f5f7;
  margin-left: $sidebar-width;
  padding: 18px 32px;
}

.sidebar {
  @include sidebar-width($sidebar-width);
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none !important;
  color: var(--neutral) !important;

  color: white;
  padding: 10px;
  display: block;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--primary-rgba);
    cursor: pointer;
  }
}

.top-menu,
.bottom-menu {
  display: flex;
  flex-direction: column;
}

.spacer {
  flex: 1;
}

.active {
  background-color: var(--primary-100);
}

.notification-container {
  background: var(--primary-100);
  width: 38px;
  height: 38px;
  padding: 2px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-sideBar {
  width: 250px;
  height: 80px;
  background-color: #21292e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-sideBar-img {
  width: 140px !important;
  height: 25px;
  margin-left: 4px;
}

.nav-icon-img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.navLabelContainer {
  height: 56px;
  padding: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navSelectionContainer {
  width: 217px;
  height: 116px;
  display: block;
}

.headerContainer {
  display: none !important;
}

@media (max-width: 760px) {

  .menu-text,
  .menu-image {
    display: none !important;
  }

  .ipad-width {
    max-width: 100px !important;
  }
}

@media (min-width: 576px) {
  .navSelectionContainer {
    display: none;
  }
}

.overlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  margin-left: $sidebar-width;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overlayContainer::before {
  content: "";
  position: fixed;
  margin-left: $sidebar-width;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  margin-left: $sidebar-width;
  width: 50%;
  height: 100vh;
  max-height: 100vh;
  background-color: var(--neutral-gray-100);
  color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  opacity: 1;
  // animation-name: fadeInRight;
  // animation-duration: 1s;
}

.overlay-title {
  position: fixed;
  z-index: 100;
  top: 0 !important;

  height: 80px;
  width: 50%;
  background-color: #21292e;
  margin-top: 0px !important;
  align-items: center;
}

.overlay-content {
  margin-top: 100px !important;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    @include sidebar-width($sidebar-width-large);
  }

  .contentContainer {
    margin-left: $sidebar-width-large;
    width: calc(100% - $sidebar-width-large);
  }

  .overlay {
    margin-left: $sidebar-width-large;
    margin-top: 0;
    width: calc(100% - #{$sidebar-width-large});
  }

  .overlayContainer,
  .overlayContainer::before {
    margin-left: $sidebar-width-large;
  }

  .overlay-title {
    width: calc(100% - #{$sidebar-width-large});
  }

  .logo-sideBar-img {
    width: 100px !important;
    height: 20px;
    margin-left: 4px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .sidebar {
    @include sidebar-width($sidebar-width-small);
  }

  .contentContainer {
    margin-left: $sidebar-width-small;
    width: calc(100% - $sidebar-width-small);
  }

  .overlay {
    margin-left: $sidebar-width-small;
    width: calc(100% - #{$sidebar-width-small});
    margin-top: 0;
  }

  .overlayContainer,
  .overlayContainer::before {
    margin-left: $sidebar-width-small;
  }

  .overlay-title {
    width: calc(100% - #{$sidebar-width-small});
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
  .sidebar {
    @include sidebar-width($sidebar-width-small);
  }

  .contentContainer {
    margin-left: $sidebar-width-small;
    width: calc(100% - $sidebar-width-small);
  }

  .overlay {
    margin-left: $sidebar-width-small;
    width: calc(100% - #{$sidebar-width-small});
    margin-top: 0;
  }

  .overlayContainer,
  .overlayContainer::before {
    margin-left: $sidebar-width-small;
  }

  .overlay-title {
    width: calc(100% - #{$sidebar-width-small});
  }
}